


















import { Component, Vue } from "vue-property-decorator";

@Component
export default class RegisterSuccess extends Vue {  
  
  backToLogin() {
    this.$router.push("/email");
  }

}
