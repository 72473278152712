import axios from 'axios';
import { i18n } from '@/plugins/i18n'
import { Trans } from '@/plugins/Translation'


const API = process.env.VUE_APP_ROOT_API;
const headers = {
    'authorizationToken':  process.env.VUE_APP_TOKEN,
    'Accept': '*/*',    
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

class AuthService {
  emailSignIn(email: string){
    Trans.setI18nLanguageInServices(i18n.locale); 
    const query = this.querystring();
    const v = this.version();
    return axios.get(API+'/api/'+v+'/members/verify?email='+email+query, { headers })
    .then(response => {
      //console.log('response', response)
      if(response.data){
        localStorage.setItem('email', email);
      }
      return response.data;
    });
  }
  
  login(password: string) {
    Trans.setI18nLanguageInServices(i18n.locale); 
    const email = localStorage.getItem('email');
    const v = this.version();
    return axios
      .post(API+'/api/'+v+'/users/login', {
        email,
        password
      }, {headers})
      .then(response => {
        if (response.data.token) {
          localStorage.removeItem('email');
          localStorage.setItem('token', JSON.stringify(response.data.token));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('token');
  }

  register(password: string) {
    const email = localStorage.getItem('email');
    const e = this.querystring();
    const data = this.querydata();
    const v = this.version();
    Trans.setI18nLanguageInServices(i18n.locale); 
    return axios.post(API+'/api/'+v+'/users', {
      email,
      password,
      e,
      data
    }, {headers});
  }

  sendVerification(email: string) {
    Trans.setI18nLanguageInServices(i18n.locale); 
    return axios
      .post(API+'/api/v1/users/email-verification', {
        "email":email
      }, {headers})
      .then(response => {
        return response.data;
      });
  }  
  
  forgotPassword(email: string) {
    Trans.setI18nLanguageInServices(i18n.locale); 
    return axios
      .post(API+'/api/v1/users/forgot-password', {
        email
      })
      .then(response => {
        return response;
      });
  }
  
  reset(password: string, confirmPassword: string) {
    const email = localStorage.getItem('email');
    Trans.setI18nLanguageInServices(i18n.locale); 
    return axios
      .post(API+'/api/v1/users/new-password', {
        email,
        password,
        "password_confirmation": confirmPassword
      })
      .then(response => {
        return response.data;
      });
  }


  version() {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    let version = 'v1';
    if(urlParams.has('inid') || urlParams.has('uid') || urlParams.has('e') || urlParams.has('v2')){
       version = 'v2';
    }

    return version;
  }


  querystring() {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    let query: string | null = '';
    if(urlParams.has('inid')){
       query = '&inid='+urlParams.get('inid');
    }
    if(urlParams.has('uid')){
       query = '&uid='+urlParams.get('uid');
    }   
    if(urlParams.has('e')){
        query = urlParams.get('e');     
    }   

    return query;
  }

  querydata() {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    let query: string | null = '';
    if(urlParams.has('data')){
       query = urlParams.get('data');
    } 

    return query;
  }

}



export default new AuthService();