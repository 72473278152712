import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'vuex-module-decorators/dist/esm/index.js';
import VeeValidate from 'vee-validate';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import browserDetect from "vue-browser-detect-plugin";
import frValidation from 'vee-validate/dist/locale/fr';
import enValidation from 'vee-validate/dist/locale/en';

import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/Translation'


Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);
Vue.use(browserDetect);
Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('locale-switcher', require('./components/LocaleSwitcher.vue').default);
Vue.use(VeeValidate, {
  i18n,
  dictionary:{
    en: {messages: enValidation.messages, attributes: {password: 'new password', confirmPassword: 'confirm password'}},
    fr: {messages: frValidation.messages, attributes: {password: 'nouveau mot de passe', confirmPassword: 'confirmez le mot de passe'}}
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');