









































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Email extends Vue {
  private user: any = { email: ""};
  private loading: boolean = false;
  private message: string = "";
  private isdisable: boolean = false;    

  @Auth.Action
  private emailSignIn!: (data: any) => Promise<any>;

  @Auth.Action
  private sendVerification!: (data: any) => Promise<any>;
  
  created(){
    let emailStorage = localStorage.getItem('email');
    if(emailStorage){                
      this.user.email = emailStorage; 
    }      
  }

  handleEmail() {
    this.isdisable = true;
    this.loading = true;    
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        this.isdisable = false;
        return;
      }

      if (this.user) {        
        this.emailSignIn(this.user).then(
          (data) => {
            if (data.redirect) {
              this.$router.push(data.redirect);
            }          
            if(data.verified && data.isUser) {
              if (data.v2) {
                this.$router.push("/login?v2=true");
              }else{
                this.$router.push("/login");
              }
              
            } else if(data.verified && !data.isUser){
              this.handleverification();
            }
            else {
              this.message = data.message;
            }            
          },
          (error) => {
            this.isdisable = false;
            this.loading = false; 
            this.message = error;
          }
        );
        this.isdisable = true;
      }
    });
  }

  handleverification(){
    this.sendVerification(this.user).then(
      (data) => {
        if (data.message === 'Verification email sent.' || data.message === 'Le membre n\'existe pas.'){
          this.$router.push("/verify");
        } else {
          this.message = data.message;
        }   
      },(error) => {
        this.message = error;
      });
  }
}
