

































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EncryptService from "@/services/EncryptService";
const Auth = namespace("Auth");


@Component
export default class CreateNew extends Vue {
  private user: any = { password: "", confirmPassword: "" };

  private submitted: boolean = false;
  private successful: boolean = false;
  private loading: boolean = false;
  private message: string = "";

  @Auth.Action
  private register!: (data: any) => Promise<any>;

  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id")) {
      let id: string = urlParams.get("id") as string;
      EncryptService.decrypt(id).then(
        (data) => {
          if (data.message) {
            this.message = data.message;
          } else {
            localStorage.setItem("email", data.email);
          }
        },
        (error) => {
          this.message = error;
        }
      );
    }
  }

  handleCreate() {
    this.message = "";
    this.submitted = true;
    this.loading = true;
    this.$validator.validate().then((isValid) => {
      if (!isValid) {
        this.loading = false;
        return;
      }
      this.register(this.user).then(
        (data) => {          
          if (data.message) {
            this.message = data.message;
          } else {
            this.successful = true;
            this.$router.push("/success");
          }
        },
        (error) => {
          this.loading = false;
          this.message = error;
        }        
      );
      this.loading = false;
    });
  }
}
