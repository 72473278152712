














































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Login extends Vue {
  private user: any = { password: ""};
  private email: any = { email: localStorage.getItem('email')};   
  private loading: boolean = false;
  private message: string = "";
  private isdisable: boolean = false;

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Getter
  private isEmailIn!: boolean;


  @Auth.Action
  private login!: (data: any) => Promise<any>;

  @Auth.Action
  private forgotPassword!: (data: any) => Promise<any>;

  mounted() {
    //console.log(this.currentUser);
    if (!this.isLoggedIn && !this.isEmailIn) {      
      this.$router.push("/email");
    } 
  }

  backToLogin() {
    this.$router.push("/email");
  }

  handleForgotPassword() {
      this.loading = true;
    //RWL Aug 15 2024
    window.location.href = "https://save.cyberconiq.com/signin/reset";
    /*
      this.forgotPassword(this.email).then(
          (data) => {         
            this.loading = false;
            if(data.message =='Mail send successfully') {
                this.$router.push("/verify-reset");
            }else{
                this.message = 'Something went wrong';
            }     
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
      );

     */
  }

  createPassword() {
    this.$router.push("/create");
  }

  handleLogin() {
    this.isdisable = true;
    this.loading = true;    
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        this.isdisable = false;
        return;
      }

      if (this.user.password) {              
        this.login(this.user).then(
          (data) => {
            if(data.message){
              this.message = data.message;
            }else {
              window.location.href = data.redirect;
            }
          },
          (error) => {
            this.isdisable = false;
            this.loading = false; 
            this.message = error;
          }
        );
        this.isdisable = true;
      }
    });
  }
}
