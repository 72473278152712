

































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VeeValidate from "vee-validate";
import { i18n } from '@/plugins/i18n';
const Auth = namespace("Auth");

@Component
export default class App extends Vue {  
  
  beforeMount() {
    if(this.$browserDetect.isIOS || this.$browserDetect.isChromeIOS || this.$browserDetect.isIE) {
      window.location.href = 'https://intro.cyberconiq.com/browser-not-supported';
    }
    window.addEventListener("load", this.onLoad);
    window.addEventListener("beforeunload", this.onUnload);
    VeeValidate.Validator.extend("verify_password", {
        getMessage: () =>
            i18n.tc('createNew.validatePassword'),
        validate: (value: any) => {
            console.log('pangit====', value);
            const strongRegex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+={}\\[\\]|:;<>,.?\\-])(?=.{8,})"
            );
            return strongRegex.test(value);
        },
    });
  }
  beforeDestroy() {    
    window.removeEventListener("load", this.onLoad);
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onLoad(event: any) {
    window.localStorage.setItem("isSessionActive", 'true');
  }
  onUnload(event: any) {
    window.localStorage.setItem("isSessionActive", 'false');
  }

  @Auth.Action
  private signOut!: () => void;  

  logOut() {
    this.signOut();
    this.$router.push("/email");
  }
}
