import Vue from 'vue';
import VueRouter, { RouteConfig } from "vue-router";
import { Trans } from './plugins/Translation';
import Email from '@/components/Email.vue';
import Login from '@/components/Login.vue';
import Renew from '@/components/Renew.vue';
import CreateNew from '@/components/CreateNew.vue';
import Verify from '@/components/Verify.vue';
import RegisterSuccess from '@/components/RegisterSuccess.vue';
import ResetPassword from '@/components/ResetPassword.vue';
import VerifyReset from '@/components/VerifyReset.vue';
import ResetSuccess from '@/components/ResetSuccess.vue';


Vue.use(VueRouter);

const { isNavigationFailure, NavigationFailureType } = VueRouter;




const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'email',
    beforeEnter: Trans.routeMiddleware
  },
  {
    path: '/email',
    component: Email
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Renew
  },
  {
    path: '/create',
    name: 'create',
    component: CreateNew
  },
  {
    path: '/success',
    name: 'success',
    component: RegisterSuccess
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPassword
  },
  {
    path: '/reset-success',
    name: 'reset-success',
    component: ResetSuccess
  },
  {
    path: '/verify-reset',
    name: 'verify-reset',
    component: VerifyReset
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify
  },
  {
    path: '/profile',
    name: 'profile',
    // lazy-loaded
    component: () => import('./components/Profile.vue')
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect (to) {
      return Trans.getUserSupportedLang()
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/email', '/login', '/register', '/create'];
  const authRequired = !publicPages.includes(to.path);  
  const isSessionActive = localStorage.getItem('isSessionActive') == 'true'? true: false;

  // trying to access a restricted page + not logged in
  // redirect to email page
  if (authRequired && (!isSessionActive)) {
    next({path: '/email'});  
  }else {
    next();
  }
});


export default router;

