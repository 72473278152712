
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Verify extends Vue {
  mounted(){
    let emailStorage = localStorage.getItem('email');
    if(emailStorage){
      localStorage.removeItem('email');
    }
  }
}
