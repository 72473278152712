import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/trans'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import es from '@/locales/es.json'

const url = window.location.search;
const url_params = new URLSearchParams(url);
const lang = url_params.get('lang');

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: (lang) ?? DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { en, fr, es }// set locale messages
})
