import axios from 'axios';

const API = process.env.VUE_APP_ROOT_API;
const headers = {
  'authorizationToken':  process.env.VUE_APP_TOKEN,
  'Accept': '*/*',    
  'Content-type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
class EncryptService {    
    
    decrypt(id:any) {
       return axios.get(API+'/api/v1/users/decrypt?id='+id, {headers})
        .then(response => {
            //console.log('response', response)      
            return response.data;
        });
    }
   
}

export default new EncryptService();

    

    
    