



































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EncryptService from "@/services/EncryptService";
const Auth = namespace("Auth");


@Component
export default class ResetPassword extends Vue {
  private user: any = { password: "", confirmPassword: "" };
  private loading: boolean = false;
  private submitted: boolean = false;
  private successful: boolean = false;
  private message: string = "";

  @Auth.Action
  private reset!: (data: any) => Promise<any>;  

  get isFormValid () {
        return Object.keys(this.user).every(key => this.user[key].valid)
  }

    created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id")) {
      let id: string = urlParams.get("id") as string;
      EncryptService.decrypt(id).then(
        (data) => {
          if (data.message) {
            this.message = data.message;
          } else {
            localStorage.setItem("email", data.email);
          }
        },
        (error) => {
          this.message = error;
        }
      );
    }
  }

  handleReset() {
    this.message = "";
    this.submitted = true;
    this.loading = true;

    this.$validator.validate().then((isValid) => {
      if (isValid) {
        this.reset(this.user).then(
          (data) => {
            this.successful = true;
            this.message = "Your Password Has Been Reset";
            this.$router.push("/reset-success");            
          },
          (error) => {
            this.message = error;
            this.loading = false;
            this.successful = false;
          }
        );
      }
    });
  }

}
