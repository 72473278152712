


















import { Component, Vue } from "vue-property-decorator";
@Component
export default class ResetSuccess extends Vue {
  backToLogin() {
    this.$router.push("/email");
  }
}
