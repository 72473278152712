

















import { Trans } from '@/plugins/Translation';
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LocaleSwitcher extends Vue {
  
    get supportedLanguages (){
      return Trans.supportedLanguages;
    }
    get currentLanguage () {
      return Trans.currentLanguage;
    }
  
  
    async changeLanguage (e : any) {
      const lang = e.target.value;
      const to = this.$router.resolve({ params: { lang } });
      await Trans.changeLanguage(lang);
      this.$router.push(to.location).catch(()=>{});
    }

    isCurrentLanguage (lang: any) {
      return lang === this.currentLanguage;
    }
  
}

