import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/services/AuthService';

const storedUser = localStorage.getItem('user');

@Module({ namespaced: true })
class User extends VuexModule {
  public status = storedUser ? { loggedIn: true, email:true } : { loggedIn: false, email:false };
  public user = storedUser ? JSON.parse(storedUser) : null;   

  @Mutation
  public loginSuccess(user: any): void {
    this.status.loggedIn = true;
    this.status.email = true;
    this.user = user;
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.status.email = false;
    this.user = null;
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false;
  }
  @Mutation
  public resetSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public resetFailure(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public ForgotSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public ForgotFailure(): void {
    this.status.loggedIn = false;
  }  

  @Action({ rawError: true })
  emailSignIn(data:any): Promise<any>{
    return AuthService.emailSignIn(data.email).then(
      user => {
        this.status.email = true;        
        return Promise.resolve(user);
      },
      error => {
        this.context.commit('loginFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    )
  }

  @Action({rawError: true})
  sendVerification(data: any): Promise<any> {
    return AuthService.sendVerification(data.email).then(
      user => {        
        this.context.commit('loginFailure', user);
        return Promise.resolve(user);
      },
      error => {
        this.context.commit('loginFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  login(data: any): Promise<any> {
    return AuthService.login(data.password).then(
      user => {        
        this.context.commit('loginSuccess', user);
        return Promise.resolve(user);
      },
      error => {
        this.context.commit('loginFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action
  signOut(): void {
    AuthService.logout();
    this.context.commit('logout');
  }

  @Action({ rawError: true })
  register(data: any): Promise<any> {
    return AuthService.register(data.password).then(
      response => {
        this.context.commit('registerSuccess');        
        return Promise.resolve(response.data);
      },
      error => {
        this.context.commit('registerFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  
  @Action({ rawError: true })
  forgotPassword(data: any): Promise<any> {
    return AuthService.forgotPassword(data.email).then(
      response => {
        this.context.commit('ForgotSuccess');        
        return Promise.resolve(response.data);
      },
      error => {
        this.context.commit('ForgotFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  reset(data: any): Promise<any> {
    return AuthService.reset(data.password, data.confirmPassword).then(
      response => {
        this.context.commit('resetSuccess');        
        return Promise.resolve(response.data);
      },
      error => {
        this.context.commit('resetFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }

  get isEmailIn(): boolean {
    return this.status.email;
  }
}

export default User;